// StickyHeader.js
import React from "react";
import { useLocation } from "react-router-dom";
import useSessionData from "../../../../utils/helpers/data/useSessionData";
import mixpanel from "../../../../mixpanel";

const StickyBanner = ({
  primary,
  id,
  copy,
  icon,
  ctaText,
  ctaLink,
  dismissable,
  dismissAction,
}) => {
  const { search } = useLocation();
  const { stage } = Object.fromEntries(new URLSearchParams(search));
  const { states } = useSessionData();

  // Event handler to track the click event
  const handleBannerCtaClick = () => {
    mixpanel.track("Clicked Banner CTA:", {
      data: { instanceId: id, stage: stage, states: states },
    });
  };

  const handleDismissAction = () => {
    mixpanel.track("Clicked To Dismiss Banner", {
      data: { instanceId: id },
    });
    dismissAction(true);
  };

  return (
    <div
      id={id}
      className={`flex justify-between w-full p-4 border-b ${
        primary ? "border-blue-200 bg-blue-50" : "border-gray-200 bg-gray-50"
      }`}
    >
      <div className="flex items-center mx-auto">
        <p
          className={`flex items-center text-sm font-normal ${
            primary ? "text-blue-600" : "text-gray-500"
          }`}
        >
          <span
            className={`inline-flex p-1 me-3 rounded-lg w-6 h-6 items-center justify-center flex-shrink-0 ${
              primary ? "bg-blue-200" : "bg-gray-200"
            }`}
          >
            {icon}
          </span>
          <span>
            {copy}
            <a
              href={ctaLink}
              className="ml-2 inline font-medium text-blue-600 hover:text-blue-800 underline underline-offset-2 decoration-600 decoration-solid"
              onClick={handleBannerCtaClick} // Track click event
            >
              {`${ctaText}`}
            </a>
          </span>
        </p>
      </div>
      {dismissable && (
        <button
          type="button"
          className={`relative items-center rounded-lg p-1.5 text-sm sm:relative sm:end-0 sm:top-0 sm:flex ${
            primary
              ? "text-blue-500 hover:bg-blue-200 hover:text-blue-700"
              : "text-gray-500 hover:bg-gray-200 hover:text-gray-700"
          }`}
          onClick={handleDismissAction}
        >
          <svg
            className="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      )}
    </div>
  );
};

export default StickyBanner;
