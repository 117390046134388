// Header.js
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./header-elements/Navbar";
import { getPageSettingProperty } from "../../../utils/pageSettings";
import StickyBanner from "./header-elements/StickyBanner";

const Header = ({ className, progressBar }) => {
  const { pathname, search } = useLocation();
  const { seller } = Object.fromEntries(new URLSearchParams(search));

  const [hideBetaBanner, setHideBetaBanner] = useState(false);
  const [hideEmailBanner, setHideEmailBanner] = useState(false);

  const showStepper = getPageSettingProperty(pathname, "showStepper");

  const showBetaBanner = () => {
    const validPaths = ["/schedule", "/review", "/trip", "/checkout"];
    return validPaths.includes(pathname) && !seller && !hideBetaBanner;
  };

  const showEmailBanner = () => {
    const validPaths = ["/"];
    return validPaths.includes(pathname) && !hideEmailBanner;
  };

  return (
    <div className={`bg-white sticky top-0 z-20 ${className}`}>
      {showBetaBanner() && (
        <StickyBanner
          id="beta-banner"
          primary={false}
          dismissable={true}
          dismissAction={setHideBetaBanner}
          copy="We're in beta! Notice any issues? Get $10 off for letting us know."
          icon={
            <svg
              className="w-3 h-3 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 18 19"
            >
              <path d="M15 1.943v12.114a1 1 0 0 1-1.581.814L8 11V5l5.419-3.871A1 1 0 0 1 15 1.943ZM7 4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v5a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V4ZM4 17v-5h1v5H4ZM16 5.183v5.634a2.984 2.984 0 0 0 0-5.634Z" />
            </svg>
          }
          ctaText="Contact Us"
          ctaLink="/report-issue"
        />
      )}
      {showEmailBanner() && (
        <StickyBanner
          id="email-list-banner"
          primary={true}
          dismissable={true}
          dismissAction={setHideEmailBanner}
          copy="Get $10 Off Your First Pickup!"
          icon={
            <svg
              className="w-4 h-4 text-blue-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8.891 15.107 15.11 8.89m-5.183-.52h.01m3.089 7.254h.01M14.08 3.902a2.849 2.849 0 0 0 2.176.902 2.845 2.845 0 0 1 2.94 2.94 2.849 2.849 0 0 0 .901 2.176 2.847 2.847 0 0 1 0 4.16 2.848 2.848 0 0 0-.901 2.175 2.843 2.843 0 0 1-2.94 2.94 2.848 2.848 0 0 0-2.176.902 2.847 2.847 0 0 1-4.16 0 2.85 2.85 0 0 0-2.176-.902 2.845 2.845 0 0 1-2.94-2.94 2.848 2.848 0 0 0-.901-2.176 2.848 2.848 0 0 1 0-4.16 2.849 2.849 0 0 0 .901-2.176 2.845 2.845 0 0 1 2.941-2.94 2.849 2.849 0 0 0 2.176-.901 2.847 2.847 0 0 1 4.159 0Z"
              />
            </svg>
          }
          ctaText="Claim Discount"
          ctaLink="/email-list"
        />
      )}
      <div className="w-full max-w-screen-xl mx-auto">
        <Navbar
          showStepper={showStepper}
          seller={seller}
          progressBar={progressBar}
        />
        <hr className="border-gray-200 sm:mx-auto dark:border-gray-700" />
      </div>
    </div>
  );
};

export default Header;
